import { gql } from "@apollo/client";
import {
  OnboardingSurveyFields,
  OrganizationFields,
  InsuranceConfigurationFields,
  InsuranceTransactionFields,
  BillingProviderFields,
} from "./types";

export const GET_ORGANIZATIONS = gql`
  query GetOrganizations {
    organizations {
      success
      data {
        ${OrganizationFields}
      }
    }
  }
`;

export const GET_ORGANIZATION = gql`
  query GetOrganization($organizationId: ID!) {
    organization(organizationId: $organizationId) {
      message
      success
      data {
        ${OrganizationFields}
      }
    }
  }
`;

export const GET_ORGANIZATIONS_BY_IDS = gql`
  query GetOrganizationsByIds($organizationIds: [ID!]!){
    organizationsByIds(organizationIds: $organizationIds){
      success
      data {
        ${OrganizationFields}
      }
    }
  }
`;

export const GET_ORGANIZATION_BY_TOKEN = gql`
  query GetOnboardingOrganization($token: String!) {
    organizationByOnboardingToken(token: $token) {
      success
      data {
        ${OrganizationFields}
      }
    }
  }
`;

export const GET_BILLING_PROVIDER = gql`
  query GetBillingProviderById($id: ID!) {
    billingProviderById(id: $id) {
      success
      message
      data
    }
  }
`;

export const GET_ONBOARDING_SURVEYS_BY_ORGANIZATION = gql`
  query GetOnboardingSurveysByOrganization($organizationId: ID!) {
    onboardingSurveysByOrganizationId(organizationId: $organizationId) {
      success
      message
      data {
        ${OnboardingSurveyFields}
      }
    }
  }
`;

export const GET_IN_NETWORK_INSURANCE_BILLING_CONFIGURATIONS = gql`
  query GetInNetworkInsuranceBillingConfigurations {
    inNetworkInsuranceBillingConfigurations {
      success
      message
      data {
        ${InsuranceConfigurationFields}
      }
    }
  }
`;

export const GET_IN_NETWORK_INSURANCE_BILLING_PROVIDERS = gql`
  query GetInNetworkInsuranceBillingProviders {
    inNetworkBillingProviders {
      success
      message
      data {
        ${BillingProviderFields}
      }
    }
  }
`;

export const GET_IN_NETWORK_INSURANCE_ACCOUNT_TRANSACTIONS = gql`
  query GetInNetworkInsuranceAccountTransactions(
    $input: GetInsuranceAccountTransactionsInput!
    $pagination: PaginationInput!
  ) {
    response: inNetworkInsuranceAccountTransactions(
      input: $input
      pagination: $pagination
    ) {
      success
      message
      data {
        page
        total
        data {
          ${InsuranceTransactionFields}
        }
      }
    }
  }
`;

export const GET_CUSTOMER_PORTAL_LINK = gql`
  query GetCustomerPortalLink($organizationId: ID!) {
    customerPortalLink(organizationId: $organizationId) {
      success
      message
      data
    }
  }
`;

export const GET_CONNECT_ONBOARDING_LINK = gql`
  query GetConnectOnboardingLink($organizationId: ID!) {
    connectOnboardingLink(organizationId: $organizationId) {
      success
      message
      data
    }
  }
`;

export const GET_DASHBOARD_LINK = gql`
  query GetDashboardLink($organizationId: ID!) {
    accountDashboardLink(organizationId: $organizationId) {
      success
      message
      data
    }
  }
`;

export const GET_DOMAINS_BY_ORGANIZATION = gql`
  query GetDomainsByOrganization(
    $organizationId: ID!
    $pagination: PaginationInput!
  ) {
    response: domainsByOrganizationId(
      organizationId: $organizationId
      pagination: $pagination
    ) {
      success
      data {
        total
        page
        data {
          _id
          title
          description
          organizationId
          goals {
            _id
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;
