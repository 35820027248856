import { EventFields } from "../Event/types";
import { InsuranceConfigurationSummaryFields } from "../Organization/types";

export const ActivityFields = `
    _id
    createdAt
    activityTemplateId 
    title
    description
    duration
    lastPing
    date
    scheduledEndAt
    status
    userId 
    userName
    lastStartedAt
    members {
        memberId
        memberDisplayName
        primaryInsuranceCompanyId
        defaultDiagnosisCodes {
            code
            description
        }
    }
    organizationId
    sync
    
    billable
    billingDetails {
        memberId
        placeOfService
        diagnosisCodes
        cost {
            _id
            procedure
            description
            unitPeriod
            cost
            modifiers
            recommendingProviderRequired
            insurance { ${InsuranceConfigurationSummaryFields} }
        }
    }

    syncAppointment
    appointmentDetails {
        locationType
        locationFacility
        locationDepartment
        locationRoom
    }

    tasks {
        id
        type
        status
        statusMessage
        formId
        description
        flowTemplateTitle
        flowId
        event {
            ${EventFields}
        }
    }
`;
