import {
  ActionIcon,
  Alert,
  Button,
  Group,
  SimpleGrid,
  Stack,
  Table,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import { IconUserExclamation, IconX } from "@tabler/icons-react";
import { FieldArray } from "formik";
import { groupBy } from "lodash";
import { useMemo } from "react";
import { Plus } from "react-feather";
import {
  ActivityCostConfigurationMeta,
  ActivityMemberMeta,
  BillingCostConfiguration,
  DefaultBillingDetails,
  InsuranceBillingConfiguration,
  InsuranceBillingConfigurationSummary,
  UpdateActivityBillingDetails,
} from "src/graphql";
import { useAuthContext } from "src/hooks";
import {
  placeOfServiceCodeLabelMap,
  placeOfServiceCodeOptions,
} from "src/pages/organization/PlaceOfServiceCodesSection";
import { SelectOption } from "src/types";
import {
  arrayToKeyedObj,
  wrapSelectOption,
  ySelectOptionSchema,
} from "src/utils";
import * as Yup from "yup";
import { FormikMultiSelect, FormikSelect } from "../input";

export const BillingDetailsSchema = Yup.object({
  members: Yup.array(
    ySelectOptionSchema(Yup.string().required()).strict(true)
  ).required("Members are required"),

  insurance: ySelectOptionSchema(Yup.string().required()).required(
    "Insurance is required"
  ),

  cost: ySelectOptionSchema(Yup.string().required()).required(
    "Cost is required"
  ),

  diagnosisCodes: Yup.array(
    ySelectOptionSchema(Yup.string().required()).strict(true)
  )
    .min(1, "At least one diagnosis code is required")
    .required("Diagnosis code is required"),

  placeOfService: ySelectOptionSchema(Yup.string().required())
    .strict(true)
    .required("Place of service is required"),
});

export type BillingDetailsFormValues = Yup.InferType<
  typeof BillingDetailsSchema
>;

type BillingDetailsFieldsProps = {
  isSingleMemberMode: boolean;

  fieldsPrefix: string;
  values: BillingDetailsFormValues[];
  setFieldValue: (field: string, value: unknown) => void;

  members: ActivityMemberMeta[];
  defaults: DefaultBillingDetails | null;
};

export const BillingDetailsFields = ({
  isSingleMemberMode,

  fieldsPrefix,
  setFieldValue,
  values,

  members,
  defaults,
}: BillingDetailsFieldsProps) => {
  const { selectedOrganization } = useAuthContext();

  const hasMissingMembers = useMemo(() => {
    const valueMemberIds = values.flatMap((v) => v.members.map((m) => m.value));
    const activityMembers = members.map((m) => m.memberId);
    return valueMemberIds.length !== activityMembers.length;
  }, [values, members]);

  const { baseInsurances, insuranceOptions, costOptions } = useMemo(() => {
    const baseInsurances = isSingleMemberMode
      ? selectedOrganization.billingInsuranceConfigurations
      : selectedOrganization.billingInsuranceConfigurations.filter((i) =>
          members.some((m) => m.primaryInsuranceCompanyId === i._id)
        );

    const insuranceOptions = baseInsurances.map((i) => ({
      label: i.insurance,
      value: i._id,
    }));

    const costOptions: Record<string, SelectOption<string>[]> =
      Object.fromEntries(
        baseInsurances.map((i) => [
          i._id,
          i.costs.map(wrapCostConfigurationIntoOption),
        ])
      );

    // Add in archived missing insurances and costs
    for (const value of values) {
      if (!insuranceOptions.some((i) => i.value === value.insurance.value))
        insuranceOptions.push({
          label: `${value.insurance.label} (Archived)`,
          value: value.insurance.value,
        });

      const costs =
        costOptions[value.insurance.value] ??
        (costOptions[value.insurance.value] = []);

      if (!value.cost) continue;
      const isCostPresent = costs.some((o) => o.value === value.cost.value);
      if (!isCostPresent) costOptions[value.insurance.value].push(value.cost);
    }

    return {
      baseInsurances,
      insuranceOptions,
      costOptions,
    };
  }, [
    values,
    members,
    isSingleMemberMode,
    selectedOrganization.billingInsuranceConfigurations,
  ]);

  const unusedInsurances = useMemo(
    () =>
      baseInsurances.filter((option) =>
        values.every((value) => value.insurance.value !== option._id)
      ),
    [baseInsurances, values]
  );

  const diagnosisOptions = useMemo(
    () =>
      selectedOrganization.diagnosisCodes.map(({ code, description }) => ({
        label: `${code} - ${description ?? "No description"}`,
        value: code,
      })),
    [selectedOrganization.diagnosisCodes]
  );

  const organizationPlaceOfServiceOptions = useMemo(
    () =>
      placeOfServiceCodeOptions.filter((option) =>
        selectedOrganization.placeOfServiceCodes.includes(
          parseInt(option.value, 10)
        )
      ),
    [selectedOrganization.placeOfServiceCodes]
  );

  if (isSingleMemberMode)
    return (
      <Stack spacing={0}>
        <Stack spacing={0} mb="sm">
          <Title size={16}>Claim Details</Title>
          <Text size="sm" color="dimmed">
            Details of the claim to be generated.
          </Text>
        </Stack>

        <SimpleGrid cols={2} verticalSpacing={4}>
          <FormikSelect
            required
            name={`${fieldsPrefix}.0.insurance`}
            label="Insurance"
            options={insuranceOptions}
            onChangeOverride={(option) => {
              // This is empty when the member has no insurance
              if (values.length > 0) {
                setFieldValue(`${fieldsPrefix}.0.insurance`, option);
                setFieldValue(`${fieldsPrefix}.0.cost`, undefined);
                return;
              }

              // If there are no members
              // we can't just set the insurance and cost
              const insurance =
                baseInsurances.find((i) => i._id === option?.value) ?? null;

              setFieldValue(fieldsPrefix, [
                wrapInsuranceGroupFromDefaults({
                  insurance: insurance,
                  members: [members[0]],
                  defaults: defaults,
                }),
              ]);
            }}
          />
          <FormikSelect
            required
            name={`${fieldsPrefix}.0.cost`}
            label="Procedure and Modifiers"
            options={costOptions[values[0]?.insurance.value] ?? []}
          />
          <FormikMultiSelect
            required
            name={`${fieldsPrefix}.0.diagnosisCodes`}
            label="Diagnosis"
            options={diagnosisOptions}
          />
          <FormikSelect
            required
            name={`${fieldsPrefix}.0.placeOfService`}
            label="Place of Service"
            options={organizationPlaceOfServiceOptions}
          />
        </SimpleGrid>
      </Stack>
    );

  return (
    <FieldArray name={fieldsPrefix}>
      {(arrayHelpers) => (
        <Stack spacing={0}>
          <Group position="apart" noWrap>
            <Stack spacing={0} mb="sm">
              <Title size={16}>Claim Details</Title>
              <Text size="sm" color="dimmed" maw={500}>
                Details of the claim to be generated grouped by insurance. Each
                insurance group represents a group of members with the same
                insurance.
              </Text>
            </Stack>

            <Button
              compact
              key="add-limits"
              color="green"
              leftIcon={<Plus />}
              disabled={unusedInsurances.length === 0}
              onClick={() =>
                arrayHelpers.push(
                  wrapInsuranceGroupFromDefaults({
                    insurance: unusedInsurances[0],
                    defaults: defaults,
                    members: members.filter(
                      ({ primaryInsuranceCompanyId }) =>
                        primaryInsuranceCompanyId === unusedInsurances[0]._id
                    ),
                  })
                )
              }
            >
              Add insurance group
            </Button>
          </Group>

          {hasMissingMembers && (
            <Alert
              color="orange"
              icon={<IconUserExclamation />}
              maw={650}
              mb="sm"
            >
              Some members are missing from the insurance groups. Please make
              sure all members have a primary insurance company set if you want
              to generate claims for them.
            </Alert>
          )}

          <Table horizontalSpacing={4}>
            <thead>
              <tr>
                <TableHeader text="Insurance" required />
                <TableHeader text="Procedure and Modifiers" required />
                <TableHeader text="Diagnosis" required />
                <TableHeader text="Place of Service" required />
                <TableHeader text="Actions" />
              </tr>
            </thead>

            <tbody>
              {values.map(({ insurance }, index) => {
                return (
                  <tr key={`${fieldsPrefix}.${index}`}>
                    <td width="25%" style={{ verticalAlign: "top" }}>
                      <Text>{insurance.label}</Text>
                    </td>
                    <td width="25%" style={{ verticalAlign: "top" }}>
                      <FormikSelect
                        name={`${fieldsPrefix}.${index}.cost`}
                        options={costOptions[insurance.value] ?? []}
                      />
                    </td>
                    <td width="25%" style={{ verticalAlign: "top" }}>
                      <FormikMultiSelect
                        maw={200}
                        name={`${fieldsPrefix}.${index}.diagnosisCodes`}
                        options={diagnosisOptions}
                      />
                    </td>
                    <td width="25%" style={{ verticalAlign: "top" }}>
                      <FormikSelect
                        name={`${fieldsPrefix}.${index}.placeOfService`}
                        options={organizationPlaceOfServiceOptions}
                      />
                    </td>
                    <td width="50px" style={{ verticalAlign: "top" }}>
                      <Group position="center" pt={8}>
                        <Tooltip label="Remove claim details for all members with this insurance">
                          <ActionIcon
                            title="Remove"
                            size="sm"
                            color="red"
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            <IconX />
                          </ActionIcon>
                        </Tooltip>
                      </Group>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Stack>
      )}
    </FieldArray>
  );
};

type TableHeaderProps = {
  text: string;
  required?: boolean;
};

const TableHeader = ({ text, required }: TableHeaderProps) => (
  <th>
    {text}
    {required && (
      <Text span color="red">
        *
      </Text>
    )}
  </th>
);

type ActivityBillingDetailsInput = {
  cost: ActivityCostConfigurationMeta;
  diagnosisCodes: string[];
  placeOfService: number;
  memberId: string;
};

type WrapBillingDetailsOptions = {
  details: ActivityBillingDetailsInput[];
  members: ActivityMemberMeta[];
};

export const wrapBillingDetails = ({
  details,
  members,
}: WrapBillingDetailsOptions): BillingDetailsFormValues[] => {
  const memberGroups = groupBy(details, (d) => d.cost._id);
  const memberMap = Object.fromEntries(members.map((m) => [m.memberId, m]));

  // Code assumes that all members sharing the same cost configuration
  // share the same insurance company
  return Object.values(memberGroups)
    .map(([detail]) => {
      if (!detail) return null;

      const members = details
        .map((d) => memberMap[d.memberId])
        .filter((m): m is ActivityMemberMeta => !!m);

      return {
        insurance: wrapInsuranceIntoOption(detail.cost.insurance),
        members: members
          .filter(
            (m) => m.primaryInsuranceCompanyId === detail.cost.insurance._id
          )
          .map(wrapMetaMemberIntoOption),
        cost: wrapCostConfigurationIntoOption(detail.cost),
        diagnosisCodes: detail.diagnosisCodes.map(wrapSelectOption),
        placeOfService: {
          label: placeOfServiceCodeLabelMap[detail.placeOfService.toString()],
          value: detail.placeOfService.toString(),
        },
      };
    })
    .filter((value): value is BillingDetailsFormValues => value !== null);
};

type WrapDefaultBillingDetailsOptions = {
  members: ActivityMemberMeta[];
  insurances: InsuranceBillingConfiguration[];
  defaults: DefaultBillingDetails | null;
};

export const wrapDefaultBillingDetails = ({
  insurances,
  members,
  defaults,
}: WrapDefaultBillingDetailsOptions): BillingDetailsFormValues[] => {
  const insuranceMap = Object.fromEntries(insurances.map((c) => [c._id, c]));
  const memberGroups = groupBy(members, (m) => m.primaryInsuranceCompanyId);

  return Object.entries(memberGroups)
    .map(([insuranceId, group = []]) => {
      const insurance = insuranceMap[insuranceId];
      if (!insurance) return null;

      return wrapInsuranceGroupFromDefaults({
        members: group,
        insurance,
        defaults,
      });
    })
    .filter((value): value is BillingDetailsFormValues => value !== null);
};

type WrapInsuranceGroupDefaultsOptions = {
  insurance: InsuranceBillingConfiguration | null;
  members: ActivityMemberMeta[];
  defaults: DefaultBillingDetails | null;
};

const wrapInsuranceGroupFromDefaults = ({
  insurance,
  members,
  defaults,
}: WrapInsuranceGroupDefaultsOptions): BillingDetailsFormValues => {
  // Procedure
  const defaultCost = defaults?.procedure
    ? insurance?.costs.find((cost) => cost.procedure === defaults.procedure)
    : insurance?.costs[0];

  // Place of service
  const defaultPlaceOfService = defaults?.placeOfService ?? "";
  const placeOfService = placeOfServiceCodeLabelMap[defaultPlaceOfService]
    ? parseInt(defaultPlaceOfService, 10)
    : 11;

  // Diagnosis codes
  const diagnosis = [
    ...new Set([
      ...(defaults?.diagnosisCodes ?? []),
      ...members.flatMap((m) =>
        m.defaultDiagnosisCodes.map((code) => code.code)
      ),
    ]),
  ];

  return {
    insurance: insurance
      ? wrapInsuranceIntoOption(insurance)
      : (undefined as unknown as SelectOption<string>),
    members: members.map(wrapMetaMemberIntoOption),
    cost: defaultCost
      ? wrapCostConfigurationIntoOption(defaultCost)
      : (undefined as unknown as SelectOption<string>),
    diagnosisCodes: diagnosis.map(wrapSelectOption),
    placeOfService: {
      label: placeOfServiceCodeLabelMap[placeOfService.toString()],
      value: placeOfService.toString(),
    },
  };
};

const wrapMetaMemberIntoOption = (
  member: ActivityMemberMeta
): SelectOption<string> => ({
  label: member.memberDisplayName,
  value: member.memberId,
});

const wrapInsuranceIntoOption = (
  insurance:
    | InsuranceBillingConfiguration
    | InsuranceBillingConfigurationSummary
): SelectOption<string> => ({
  label: insurance.insurance,
  value: insurance._id,
});

const wrapCostConfigurationIntoOption = (
  cost: BillingCostConfiguration | ActivityCostConfigurationMeta
): SelectOption<string> => ({
  label: getCostConfigurationLabel(cost),
  value: cost._id,
});

const getCostConfigurationLabel = ({
  procedure,
  modifiers,
  description,
}: BillingCostConfiguration | ActivityCostConfigurationMeta): string => {
  const modifiersText =
    modifiers && modifiers.length > 0 ? modifiers.join(",") : null;

  const identifier = modifiersText
    ? `${procedure} ${modifiersText}`
    : procedure;

  return `${identifier} - ${
    description?.length ? description : "No description"
  }`;
};

export const unwrapBillingDetails = (
  values: BillingDetailsFormValues[],
  members: ActivityMemberMeta[]
): UpdateActivityBillingDetails[] => {
  const memberIdToMember = arrayToKeyedObj(members, "memberId");
  return values.flatMap((value) => {
    const diagnosisCodes = value.diagnosisCodes.map((option) => option.value);
    const placeOfService = parseInt(value.placeOfService.value, 10);
    return value.members
      .filter(
        (member) =>
          memberIdToMember?.[member.value]?.primaryInsuranceCompanyId ===
          value.insurance.value
      )
      .map((member) => ({
        memberId: member.value,
        diagnosisCodes,
        placeOfService,
        costId: value.cost.value,
      }));
  });
};
