import {
  ActionIcon,
  Badge,
  Group,
  Stack,
  Tooltip,
  TextInput,
  useMantineTheme,
  Checkbox,
} from "@mantine/core";
import * as React from "react";
import { TableColumn } from "react-data-table-component";
import { DownloadCloud, Edit, PhoneOff, Search } from "react-feather";
import { useHistory } from "react-router-dom";

import {
  Member,
  MemberStatus,
  ProviderRecommendationStatus,
  usePaginatedQueryMembers,
  useQueryUsers,
} from "src/graphql";
import useTwilio from "src/hooks/useTwilio";
import { getFullName } from "src/utils";
import { formatPhoneNumber } from "src/utils/phone";
import { SectionHeader, SectionSubHeader } from "../display";
import {
  DataTableComponent,
  OverflowTooltipCell,
  TableContainer,
  TableHeader,
} from "../table";
import { WidgetTab } from "../twilio/widget/tabs";
import { MemberCloseButton } from "../member-close-button";
import { DontTranslate } from "src/libs/localization/components/DontTranslate";
import { isEqual } from "lodash";
import {
  EnrollmentStatusToColor,
  EnrollmentStatusToLabel,
  RecommendationStatusToColor,
  RecommendationStatusToLabel,
} from "./util";
import { useLazyQuerySignedDocument } from "src/graphql/ESignature/hooks";
import toast from "src/libs/toast";

interface MembersCardProps {
  organizationIds: string[];
  dense?: boolean;
  fixedHeader?: boolean;
  className?: string;
  noSelect?: boolean;
  noFullName?: boolean;
  noFirstName?: boolean;
  noLastName?: boolean;
  noOrganization?: boolean;
  noDOB?: boolean;
  noMedicareStatus?: boolean;
  noMedicaidStatus?: boolean;
  noEHRType?: boolean;
  noPrimaryInsurance?: boolean;
  noSecondaryInsurance?: boolean;
  noRecommendationStatus?: boolean;
  noNotes?: boolean;
  noBatchID?: boolean;
  noFile?: boolean;
  noEmail?: boolean;
  noPhone?: boolean;
  noGroups?: boolean;
  showAssignedTo?: boolean;
  showEdit?: boolean;
  showDelete?: boolean;
  excludeInactive?: boolean;
  showOnlyRecommendationRequired?: boolean;
  checkedMembers?: Record<string, boolean>;
  setCheckedMembers?: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >;
  onMemberSelect?: (member: Member) => void;
  actionButtons?: React.ReactNode[];
  additionalColumns?: TableColumn<Member>[];

  userId?: string;
  unassigned?: boolean;
  selectedGroups?: string[];
  refetchId?: string;
  persistTableHead?: boolean;
  paginationSize?: number;
  isPaginationDisabled?: boolean;
  excludeSigned?: boolean;
  recommendationStatuses?: ProviderRecommendationStatus[];
}

const MembersTable = ({
  organizationIds,

  dense = false,
  fixedHeader,
  className,
  noSelect,
  noFullName,
  noFirstName,
  noLastName,
  noOrganization,
  noDOB,
  noMedicareStatus,
  noMedicaidStatus,
  noEHRType,
  noPrimaryInsurance,
  noSecondaryInsurance,
  noRecommendationStatus,
  noNotes,
  noBatchID,
  noFile,
  noEmail,
  noPhone,
  noGroups,
  showAssignedTo,
  showDelete,
  showEdit,
  checkedMembers,
  setCheckedMembers,
  excludeInactive = true,
  showOnlyRecommendationRequired = false,
  onMemberSelect,
  actionButtons,
  additionalColumns = [],

  userId,
  unassigned,
  selectedGroups,
  refetchId,
  persistTableHead = false,
  paginationSize = 10,
  isPaginationDisabled = false,
  excludeSigned = false,
  recommendationStatuses = [],
}: MembersCardProps) => {
  const refetchIdRef = React.useRef<string | undefined>();
  const lastGroups = React.useRef<string[] | undefined>();
  // State to track loading status of each row
  const [loadingRow, setLoadingRow] = React.useState<string>("");

  const history = useHistory();
  const theme = useMantineTheme();
  const [lazyQuerySignedDocument, { loading: signedDocumentLoading }] =
    useLazyQuerySignedDocument();
  // NOTE: Temporary solution to no assigned users in members object
  const organizationUsersQuery = useQueryUsers({
    organizationId: organizationIds[0],
  });
  const organizationUsers = organizationUsersQuery.data?.users.data || [];

  // Other member helpers
  const handleEditMember = (memberId: string) =>
    history.push(`/members/${memberId}/edit`);

  const onFileClick = async (
    memberId: string,
    organizationIds: string[],
    batchId: string,
    signatureRequestId?: string
  ) => {
    try {
      setLoadingRow(memberId);
      const url = await lazyQuerySignedDocument({
        variables: {
          memberId: memberId,
          organizationIds: organizationIds,
          providerRecommendationBatchId: batchId,
          signatureRequestId: signatureRequestId,
        },
      }).then((res) => res.data?.signedDocument?.data?.url);
      setLoadingRow("");
      if (!url) {
        throw new Error(
          "Sorry, we could not get file URL from server. Try again and contact Pear Suite Support if problem persists."
        );
      }
      window.open(url, "_blank");
    } catch (error) {
      setLoadingRow("");
      const defaultMessage =
        "Sorry, we could not get file URL from server. Try again and contact Pear Suite Support if problem persists.";

      toast.error(
        error instanceof Error
          ? error.message ?? defaultMessage
          : defaultMessage
      );
    }
  };

  const handleSelectMember =
    onMemberSelect ||
    ((member: Member) => history.push(`/members/${member._id}`));

  // Member table view
  const { dataTableProperties, setSearchTerm, setGroups, refetch } =
    usePaginatedQueryMembers({
      organizations: organizationIds,
      user: userId,
      pageSize: paginationSize,
      excludeInactive,
      showOnlyRecommendationRequired,
      unassigned,
      groups: selectedGroups,
      recommendationStatuses,
      isPaginationDisabled,
    });

  const { widget } = useTwilio();

  React.useEffect(() => {
    if (refetchIdRef.current === refetchId) {
      return;
    }

    refetchIdRef.current = refetchId;
    refetch();
  }, [refetch, refetchId, recommendationStatuses]);

  React.useEffect(() => {
    if (
      isEqual(
        (lastGroups.current ?? []).sort((a, b) => a.localeCompare(b)),
        (selectedGroups ?? []).sort((a, b) => a.localeCompare(b))
      )
    ) {
      return;
    }

    setGroups(selectedGroups);
    lastGroups.current = selectedGroups;
  }, [selectedGroups, setGroups]);

  // Table columns
  const columns: TableColumn<Member>[] = [
    {
      omit: noSelect,
      grow: 0,
      minWidth: "60px",
      center: true,
      compact: true,
      name: (
        <Checkbox
          onChange={(e) => {
            if (setCheckedMembers)
              setCheckedMembers(
                dataTableProperties.data.reduce((acc, member) => {
                  if (
                    member.providerRecommendation?.status !==
                    ProviderRecommendationStatus.Signed
                  ) {
                    return {
                      ...acc,
                      [member._id]: e.target.checked,
                    };
                  }
                  return acc;
                }, {} as Record<string, boolean>)
              );
          }}
        />
      ),
      cell: (member: Member) => (
        <Checkbox
          disabled={
            member.providerRecommendation?.status ===
            ProviderRecommendationStatus.Signed
          }
          checked={checkedMembers?.[member._id] ?? false}
          onChange={(e) => {
            if (setCheckedMembers) {
              setCheckedMembers({
                ...checkedMembers,
                [member._id]: e.target.checked,
              });
            }
          }}
        />
      ),
    },
    {
      omit: noFullName,
      name: "Name",
      sortField: "name",
      sortable: true,
      cell: (member: Member) => (
        <OverflowTooltipCell
          onClick={() => {
            handleSelectMember(member);
          }}
        >
          <DontTranslate>{getFullName(member)}</DontTranslate>
        </OverflowTooltipCell>
      ),
    },
    {
      omit: noFirstName,
      name: "First Name",
      sortField: "firstName",
      sortable: true,
      cell: (member: Member) => (
        <OverflowTooltipCell
          onClick={() => {
            handleSelectMember(member);
          }}
        >
          <DontTranslate>{member.firstName}</DontTranslate>
        </OverflowTooltipCell>
      ),
    },
    {
      omit: noLastName,
      name: "Last Name",
      sortField: "lastName",
      sortable: true,
      cell: (member: Member) => (
        <OverflowTooltipCell
          onClick={() => {
            handleSelectMember(member);
          }}
        >
          <DontTranslate>{member.lastName}</DontTranslate>
        </OverflowTooltipCell>
      ),
    },
    {
      omit: noOrganization,
      name: "Org",
      sortField: "organization",
      sortable: true,
      cell: (member: Member) => (
        <OverflowTooltipCell
          onClick={() => {
            handleSelectMember(member);
          }}
        >
          <DontTranslate>{member.organizationName}</DontTranslate>
        </OverflowTooltipCell>
      ),
    },
    {
      omit: noDOB,
      name: "DOB",
      sortField: "dob",
      sortable: true,
      cell: (member: Member) => (
        <OverflowTooltipCell
          onClick={() => {
            handleSelectMember(member);
          }}
        >
          <DontTranslate>{member?.dob ?? ""}</DontTranslate>
        </OverflowTooltipCell>
      ),
    },
    {
      omit: noMedicareStatus,
      name: "Medicare Status",
      sortField: "medicareStatus",
      sortable: true,
      cell: (member: Member) => (
        <OverflowTooltipCell
          onClick={() => {
            handleSelectMember(member);
          }}
        >
          {member.medicareStatus && (
            <Badge
              size="sm"
              color={EnrollmentStatusToColor[member.medicareStatus]}
              style={{ cursor: "default" }}
            >
              {EnrollmentStatusToLabel[member.medicareStatus]}
            </Badge>
          )}
        </OverflowTooltipCell>
      ),
    },
    {
      omit: noMedicaidStatus,
      name: "Medicaid Status",
      sortField: "medicaidStatus",
      sortable: true,
      cell: (member: Member) => (
        <OverflowTooltipCell
          onClick={() => {
            handleSelectMember(member);
          }}
        >
          {member.medicaidStatus && (
            <Badge
              size="sm"
              color={EnrollmentStatusToColor[member.medicaidStatus]}
              style={{ cursor: "default" }}
            >
              {EnrollmentStatusToLabel[member.medicaidStatus]}
            </Badge>
          )}
        </OverflowTooltipCell>
      ),
    },
    {
      omit: noEHRType,
      name: "EHR Type",
      sortField: "ehrIDType",
      sortable: true,
      cell: (member: Member) => (
        <OverflowTooltipCell
          onClick={() => {
            handleSelectMember(member);
          }}
        >
          <DontTranslate>{member?.ehrIDType ?? ""}</DontTranslate>
        </OverflowTooltipCell>
      ),
    },
    {
      omit: noPrimaryInsurance,
      name: "Primary Insurance",
      sortField: "primaryInsuranceCompany",
      sortable: true,
      cell: (member: Member) => (
        <OverflowTooltipCell
          onClick={() => {
            handleSelectMember(member);
          }}
        >
          <DontTranslate>
            {member?.primaryInsuranceCompany?.insurance ?? ""}
          </DontTranslate>
        </OverflowTooltipCell>
      ),
    },
    {
      omit: noSecondaryInsurance,
      name: "Secondary Insurance",
      sortField: "secondaryInsuranceCompany",
      sortable: true,
      cell: (member: Member) => (
        <OverflowTooltipCell
          onClick={() => {
            handleSelectMember(member);
          }}
        >
          <DontTranslate>
            {member?.secondaryInsuranceCompany?.insurance ?? ""}
          </DontTranslate>
        </OverflowTooltipCell>
      ),
    },
    {
      omit: noRecommendationStatus,
      name: "Recommendation Status",
      sortField: "providerRecommendation.status",
      sortable: true,
      cell: (member: Member) => (
        <OverflowTooltipCell
          onClick={() => {
            handleSelectMember(member);
          }}
        >
          {member.providerRecommendation?.status && (
            <Badge
              size="sm"
              color={
                RecommendationStatusToColor[
                  member.providerRecommendation?.status
                ]
              }
              style={{ cursor: "default" }}
            >
              {
                RecommendationStatusToLabel[
                  member.providerRecommendation?.status
                ]
              }
            </Badge>
          )}
        </OverflowTooltipCell>
      ),
    },
    {
      omit: noNotes,
      name: "Notes",
      cell: (member: Member) => (
        <OverflowTooltipCell
          onClick={() => {
            handleSelectMember(member);
          }}
        >
          {member.providerRecommendation?.notes ?? ""}
        </OverflowTooltipCell>
      ),
    },
    {
      omit: noBatchID,
      name: "Batch ID",
      cell: (member: Member) => (
        <OverflowTooltipCell
          onClick={() => {
            handleSelectMember(member);
          }}
        >
          <DontTranslate>
            {member?.providerRecommendation?.batch?._id ?? ""}
          </DontTranslate>
        </OverflowTooltipCell>
      ),
    },
    {
      omit: noFile,
      name: "File",
      cell: (member: Member) => {
        const signatureRequestId =
          member.providerRecommendation?.batch?.signatureRequestId;
        const providerRecommendationBatchId =
          member.providerRecommendation?.batch?._id;
        const status = member.providerRecommendation?.status;
        return (
          <>
            {providerRecommendationBatchId &&
              status === ProviderRecommendationStatus.Signed && (
                <ActionIcon
                  onClick={() =>
                    onFileClick(
                      member._id,
                      organizationIds,
                      providerRecommendationBatchId,
                      signatureRequestId
                    )
                  }
                  loading={signedDocumentLoading && loadingRow === member._id}
                >
                  <DownloadCloud size="16px" color="var(--color-pear-green)" />
                </ActionIcon>
              )}
          </>
        );
      },
    },
    {
      omit: noEmail,
      name: "Email",
      sortField: "contactInfo.email",
      sortable: true,
      cell: (member: Member) => (
        <OverflowTooltipCell
          onClick={() => {
            handleSelectMember(member);
          }}
        >
          <DontTranslate>{member?.contactInfo.email ?? ""}</DontTranslate>
        </OverflowTooltipCell>
      ),
    },
    {
      omit: noPhone,
      name: "Phone",
      sortField: "contactInfo.phone",
      sortable: true,
      cell: (member) => (
        <OverflowTooltipCell
          onClick={() => {
            widget.setTab(WidgetTab.Phone);
            widget.setPhoneNumber(member.contactInfo.phone);
            widget.expand(true);
          }}
          disabled={member.status !== MemberStatus.Active}
        >
          <Group spacing={0}>
            {formatPhoneNumber(member.contactInfo.phone)}
            {member.contactInfo.doNotCall && (
              <Tooltip label="Do Not Call">
                <PhoneOff size={13} color="red" style={{ marginLeft: 3 }} />
              </Tooltip>
            )}
          </Group>
        </OverflowTooltipCell>
      ),
    },
    {
      omit: noGroups,
      name: "Groups",
      sortable: false,
      cell: ({ contactInfo: { groups } }) => (
        <Stack spacing={5} mt={5} mb={5}>
          {Array.isArray(groups) && groups.length
            ? groups.map(({ title }, idx) => <Badge key={idx}>{title}</Badge>)
            : null}
        </Stack>
      ),
    },
    {
      name: "Assigned to",
      sortable: false,
      omit: showAssignedTo !== true,
      cell: ({ assignedUserIds = [] }) => (
        <DontTranslate>
          {assignedUserIds
            ?.map((id) => organizationUsers.find((u) => u._id === id)?.name)
            .filter((name): name is string => !!name)
            .join(", \n")}
        </DontTranslate>
      ),
    },
    ...additionalColumns,

    {
      name: "Edit",
      grow: 0,
      minWidth: "60px",
      center: true,
      compact: true,
      omit: showEdit !== true,
      cell: (member) => (
        <ActionIcon
          onClick={() => handleEditMember(member._id)}
          disabled={member.status !== MemberStatus.Active}
        >
          <Edit
            size="16"
            color={
              member.status === MemberStatus.Active
                ? theme.colors.blue[5]
                : undefined
            }
          />
        </ActionIcon>
      ),
    },
    {
      name: "Close",
      grow: 0,
      minWidth: "80px",
      center: true,
      omit: showDelete !== true,
      cell: (member) => <MemberCloseButton member={member} onClose={refetch} />,
    },
  ];

  // NOTE: I don't think this is the best way to handle this
  // This creates two filter patterns, one on the backend and the other
  // on the frontend. This is not ideal for code maintainability.
  const filteredData = excludeSigned
    ? dataTableProperties.data.filter(
        (row) =>
          row.providerRecommendation?.status !==
          ProviderRecommendationStatus.Signed
      )
    : dataTableProperties.data;

  return (
    <TableContainer>
      {dense === false && (
        <TableHeader
          placeholder="By Name, Email or Phone"
          setFilterText={(text) => setSearchTerm(text)}
          actionButtons={actionButtons}
          searchColumn={
            actionButtons === undefined || actionButtons.length === 0
              ? 12
              : undefined
          }
        />
      )}

      {dense === true && (
        <>
          <SectionHeader noGap>
            {unassigned && "Unassigned "}Members
          </SectionHeader>
          <SectionSubHeader noGap>
            <Group position="apart">
              <TextInput
                style={{ width: 200 }}
                icon={<Search height={16} />}
                size="xs"
                placeholder="By Name, Email or Phone..."
                onChange={(event) => setSearchTerm(event.target.value)}
              />

              {actionButtons && actionButtons.length >= 0 && (
                <Group>{actionButtons}</Group>
              )}
            </Group>
          </SectionSubHeader>
        </>
      )}

      <DataTableComponent
        persistTableHead={persistTableHead}
        pagination={!isPaginationDisabled}
        paginationPerPage={paginationSize}
        noCardWrapper
        dense={dense}
        noRowsPerPage
        fixedHeader={fixedHeader}
        className={className}
        columns={columns}
        highlightOnHover={true}
        pointerOnHover={true}
        onRowClicked={handleSelectMember}
        noDataText={"No assigned members found"}
        conditionalRowStyles={[
          {
            when: (row) => row.status !== MemberStatus.Active,
            style: {
              color: theme.colors.black[6],
              backgroundColor: theme.colors.gray[1],
            },
          },
        ]}
        {...dataTableProperties}
        data={filteredData}
      />
    </TableContainer>
  );
};

export default MembersTable;
